<template>
  <v-app-bar app class="appbar" clipped-left :class="classCordova">
    <v-row v-if="$route.path === '/'" no-gutters :align="'center'">
      <v-col cols="2" sm="1" class="d-flex justify-left align-center">
        <img src="/logo/logo.png" width="100" />
      </v-col>
      <v-col
        cols="10"
        sm="11"
        class="d-flex flex-row justify-end align-center px-md-2 "
        style="flex-wrap: nowrap;"
      >
        <div class="pl-2">
          <v-icon aria-label="Cerca" @click="openSearch" color="default"
            >$search</v-icon
          >
          <v-icon
            aria-label="Notifiche"
            @click="openNotifications"
            color="default"
            class="ml-5"
            >$campanella</v-icon
          >
        </div>
      </v-col>
    </v-row>
    <div v-else class="d-flex justify-space-between align-center w-100">
      <router-link
        class="router-back"
        active-class="noop"
        :to="{ path: $routerHistory.previous().path }"
      >
        <v-btn class="no-border" aria-label="Torna Indietro" icon small>
          <v-icon class="default--text back-arrow">$arrowBack</v-icon>
        </v-btn>
      </router-link>
      <h3 v-if="$route.meta.title == 'Calendario dell\'avvento'" class="category-name">
        Calendario dell'Avvento
      </h3>
      <h3 v-else class="category-name">
        {{ $route.meta.title }}
      </h3>
      <div></div>
    </div>

    <v-progress-linear
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
  </v-app-bar>
</template>
<style lang="scss">
.is-cordova {
  .appbar {
    .v-toolbar__content {
      height: 100% !important;
    }
    .category-name {
      font-size: 20px;
      color: $primary;
      flex-grow: 1;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      padding: 0 32px;
    }
  }
}
.shopping-cart-icon {
  color: var(--v-primary-base) !important;
}

@media screen and (max-width: 1360px) {
  .username {
    display: none !important;
  }
}

.v-main {
  background-color: white;
  padding-top: calc(80px + env(safe-area-inset-top)) !important;
}

.v-app-bar--fixed {
  background-color: #fcfcfc !important;
  position: fixed;
  top: 0;
  z-index: 11 !important;
  width: 100%;
  // iOS 11.0 fixes
  height: calc(
    #{$statusbarheight} + constant(safe-area-inset-top)
  ) !important; // % ($statusbarheight constant(safe-area-inset-top))
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  // iOS 11.2 fixes
  height: calc(
    #{$statusbarheight} + env(safe-area-inset-top)
  ) !important; // ($statusbarheight env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-app-bar--fixed {
    position: fixed;
    top: 0;
    z-index: 11 !important;
    height: $statusbarheightxs;
    width: 100%;
    // iOS 11.0 fixes
    height: calc(
      #{$statusbarheightxs} + constant(safe-area-inset-top)
    ) !important;
    padding-top: constant(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    // iOS 11.2 fixes
    height: calc(#{$statusbarheightxs} + env(safe-area-inset-top)) !important;
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.drawer-btn {
  height: 60px !important;
  width: 60px !important;
  label {
    font-size: 9px;
  }
}

.back-arrow {
  font-size: 15px !important;
  height: 15px !important;
  width: 15px !important;
}

.router-back {
  text-decoration: none;
  position: absolute;
}

.router-back,
.noop {
  .theme--light.v-btn:before {
    background: white !important;
  }
}
</style>
<script>
//TODO rivedere completamente la gestione dei breakpoint in modo che non si scassino su telefono....
import SearchInput from "@/components/navigation/SearchInput.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { mapActions, mapState } from "vuex";

// import newsService from "~/service/newsService";

export default {
  name: "Navbar",
  mixins: [login, cartInfo, categoryMixins],
  // data() {
  //   return {
  //     newsList: []
  //   };
  // },
  // mounted() {
  //   this.checkNews();
  //   global.EventBus.$on("updateNewsList", newsList => {
  //     this.newsList = newsList;
  //   });
  // },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    goBack() {
      this.$router.go(-1);
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    historyBack() {
      window.history.back();
    },
    async openSearch() {
      let result = await this.openDialog(SearchInput, {
        waitForResult: true,
        width: global.config.dialogMaxWidth || 600
      });
      return result;
    },
    openNotifications() {
      console.log("notifications");
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart
    }),
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      return this.cart.totalItems;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    classCordova: function() {
      return {
        "is-cordova": typeof cordova !== "undefined"
      };
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  }
};
</script>
