<template>
  <v-container fluid fill-height class="h-100 pb-0 pt-12 px-0 card-details">
    <v-layout>
      <v-flex
        xs12
        class="d-flex flex-column align-center justify-space-between"
      >
        <div>
          <div class="white--text">
            <div class="name">
              {{
                $t("card.title", {
                  name: this.user.firstName
                })
              }}
            </div>
            <div v-if="showPoints">
              <div class="message">{{ $t("card.yourPoints") }}</div>
              <div class="points">{{ userPoints }}</div>
            </div>
            <div v-if="userSpecialPoints && isBonusAvailable">
              <div class="message">I tuoi punti bonus disponibili sono</div>
              <div class="points">{{ userSpecialPoints }}</div>
            </div>
            <div v-else class="empty-space"></div>
          </div>
          <div class="d-flex justify-center align-center flex-column">
            <div class="barcode-wraper">
              <div class="mt-3 text-body-1 font-weight-bold">
                {{ $t("card.yourCard") }}
              </div>
              <barcode
                :value="user.fidelityCard"
                format="EAN13"
                :displayValue="false"
                class="align-self-center"
                flat
                :width="barcodeWidth"
                :height="barcodeHeight"
              />
              <div class="mt-n2">{{ $t("card.number") }}</div>
              <div class="text-body-1 font-weight-bold mb-2">
                {{ user.fidelityCard }}
              </div>
            </div>
            <div class="my-3 mx-12">{{ $t("card.showCard") }}</div>
          </div>
        </div>
        <div
          class="action-section d-flex grey lighten-1 flex-column default--text px-4 pb-12 pt-8 w-100"
        >
          <v-btn
            large
            block
            depressed
            rounded
            color="primary"
            @click="changePage('ActiveCoupon')"
          >
            <v-icon left>$coupon</v-icon> Coupon attivi
          </v-btn>
          <v-btn
            large
            block
            depressed
            rounded
            color="primary"
            @click="changePage('DiscountHistory')"
          >
            <v-icon left>$premi</v-icon> I tuoi movimenti
          </v-btn>
          <v-btn
            large
            block
            depressed
            rounded
            color="primary"
            @click="changePage('Discounts')"
          >
            <v-icon left>$star</v-icon> Utilizza i tuoi punti
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<style lang="scss">
.card-details {
  text-align: center;
  position: relative;
  overflow-x: hidden;
  .name {
    font-size: 6vw;
  }
  .message {
    font-size: 4vw;
  }
  .points {
    font-size: 7vw;
    font-weight: bold;
  }
  .barcode-wraper {
    height: fit-content;
    background-color: $white;
    border: 1px solid var(--v-default-base);
    width: 80vw;
    margin-top: 5vh;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    .vue-barcode-element {
      max-width: 100%;
    }
  }
  .barcode-wraper::before {
    content: "";
    position: absolute;
    top: -38px;
    left: -19px;
    width: 113px;
    height: 70px;
    background-image: url(/img_layout/carta-gala-2.png);
    background-size: cover;
    z-index: 1;
    transform: rotate(337deg);
  }
  .action-section {
    border-radius: 25px 25px 0px 0px;
    row-gap: 15px;
  }
  .empty-space {
    height: 70px;
  }
}
.card-details::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0px;
  width: 110%;
  height: 35%;
  min-height: 280px;
  background-color: $primary;
  border-radius: 0 0 100% 0;
  z-index: -1;
}
</style>

<script>
import abbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";
import { mapGetters } from "vuex";
import matomoService from "@/service/analytics/matomoService";
import CategoryService from "~/service/categoryService";

export default {
  name: "CardDetails",
  data() {
    return {
      barcodeWidth: "2",
      barcodeHeight: "100",
      walletId: "",
      userSpecialPoints: null,
      showPoints: true,
      isBonusAvailable: false
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      userPoints: "custom/userPoints"
    })
  },
  methods: {
    changePage(routeName) {
      this.$router.push({ name: routeName });
      this.$emit("submit", true);
    },
    updateBarcodeSize() {
      const screenWidth = window.innerWidth;
      if (screenWidth > 750) {
        this.barcodeWidth = 4;
        this.barcodeHeight = 180;
      } else if (screenWidth > 400) {
        this.barcodeWidth = 3;
        this.barcodeHeight = 125;
      } else {
        this.barcodeWidth = 2;
        this.barcodeHeight = 100;
      }
    },
    async checkBonusAvailability() {
      try {
        const response = await CategoryService.getCategoryBySlug(
          "donate-points"
        );

        const categoryData = response.metaData.category_bolliniElettronici;
        const activationStartDate = categoryData.ATTIVAZIONE_DATA_DA;
        const activationEndDate = categoryData.ATTIVAZIONE_DATA_FINE;
        const displayBollini = categoryData.DISPLAY_BOLLINI;
        this.walletId = categoryData.COUNTER_ID;

        if (!activationStartDate) {
          this.isBonusAvailable = false;
          return;
        }

        let today = new Date();
        let startDate = new Date(activationStartDate);
        let endDate = activationEndDate ? new Date(activationEndDate) : null;

        // Check if the current date is within the activation window
        this.isBonusAvailable =
          today >= startDate && (!endDate || today <= endDate);
        if (this.isBonusAvailable) {
          abbondanzaRegistrationService.getPoints(this.walletId).then(res => {
            this.userSpecialPoints = res.value.punti;
          });
        }
        // If bonus is available and bollini are to be displayed, set isBonusAvailable
        if (this.isBonusAvailable && displayBollini) {
          // Additional logic for bonus display can go here
        }
      } catch (error) {
        console.error("Error fetching bonus points availability", error);
        this.isBonusAvailable = false;
      }
    }
  },
  mounted() {
    this.updateBarcodeSize();
    abbondanzaRegistrationService.getPoints();
    this.checkBonusAvailability();
    matomoService.trackPageView("/cardDetails");
  }
};
</script>
