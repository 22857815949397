var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "h-100 pb-0 pt-12 px-0 card-details",
      attrs: { fluid: "", "fill-height": "" }
    },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            {
              staticClass:
                "d-flex flex-column align-center justify-space-between",
              attrs: { xs12: "" }
            },
            [
              _c("div", [
                _c("div", { staticClass: "white--text" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("card.title", { name: this.user.firstName })
                        ) +
                        " "
                    )
                  ]),
                  _vm.showPoints
                    ? _c("div", [
                        _c("div", { staticClass: "message" }, [
                          _vm._v(_vm._s(_vm.$t("card.yourPoints")))
                        ]),
                        _c("div", { staticClass: "points" }, [
                          _vm._v(_vm._s(_vm.userPoints))
                        ])
                      ])
                    : _vm._e(),
                  _vm.userSpecialPoints && _vm.isBonusAvailable
                    ? _c("div", [
                        _c("div", { staticClass: "message" }, [
                          _vm._v("I tuoi punti bonus disponibili sono")
                        ]),
                        _c("div", { staticClass: "points" }, [
                          _vm._v(_vm._s(_vm.userSpecialPoints))
                        ])
                      ])
                    : _c("div", { staticClass: "empty-space" })
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-center align-center flex-column"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "barcode-wraper" },
                      [
                        _c(
                          "div",
                          { staticClass: "mt-3 text-body-1 font-weight-bold" },
                          [_vm._v(" " + _vm._s(_vm.$t("card.yourCard")) + " ")]
                        ),
                        _c("barcode", {
                          staticClass: "align-self-center",
                          attrs: {
                            value: _vm.user.fidelityCard,
                            format: "EAN13",
                            displayValue: false,
                            flat: "",
                            width: _vm.barcodeWidth,
                            height: _vm.barcodeHeight
                          }
                        }),
                        _c("div", { staticClass: "mt-n2" }, [
                          _vm._v(_vm._s(_vm.$t("card.number")))
                        ]),
                        _c(
                          "div",
                          { staticClass: "text-body-1 font-weight-bold mb-2" },
                          [_vm._v(" " + _vm._s(_vm.user.fidelityCard) + " ")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "my-3 mx-12" }, [
                      _vm._v(_vm._s(_vm.$t("card.showCard")))
                    ])
                  ]
                )
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "action-section d-flex grey lighten-1 flex-column default--text px-4 pb-12 pt-8 w-100"
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        large: "",
                        block: "",
                        depressed: "",
                        rounded: "",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.changePage("ActiveCoupon")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("$coupon")
                      ]),
                      _vm._v(" Coupon attivi ")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        large: "",
                        block: "",
                        depressed: "",
                        rounded: "",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.changePage("DiscountHistory")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [_vm._v("$premi")]),
                      _vm._v(" I tuoi movimenti ")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        large: "",
                        block: "",
                        depressed: "",
                        rounded: "",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.changePage("Discounts")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [_vm._v("$star")]),
                      _vm._v(" Utilizza i tuoi punti ")
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }