var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "appbar",
      class: _vm.classCordova,
      attrs: { app: "", "clipped-left": "" }
    },
    [
      _vm.$route.path === "/"
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-left align-center",
                  attrs: { cols: "2", sm: "1" }
                },
                [_c("img", { attrs: { src: "/logo/logo.png", width: "100" } })]
              ),
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex flex-row justify-end align-center px-md-2",
                  staticStyle: { "flex-wrap": "nowrap" },
                  attrs: { cols: "10", sm: "11" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "pl-2" },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { "aria-label": "Cerca", color: "default" },
                          on: { click: _vm.openSearch }
                        },
                        [_vm._v("$search")]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-5",
                          attrs: {
                            "aria-label": "Notifiche",
                            color: "default"
                          },
                          on: { click: _vm.openNotifications }
                        },
                        [_vm._v("$campanella")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "d-flex justify-space-between align-center w-100" },
            [
              _c(
                "router-link",
                {
                  staticClass: "router-back",
                  attrs: {
                    "active-class": "noop",
                    to: { path: _vm.$routerHistory.previous().path }
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "no-border",
                      attrs: {
                        "aria-label": "Torna Indietro",
                        icon: "",
                        small: ""
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "default--text back-arrow" },
                        [_vm._v("$arrowBack")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.$route.meta.title == "Calendario dell'avvento"
                ? _c("h3", { staticClass: "category-name" }, [
                    _vm._v(" Calendario dell'Avvento ")
                  ])
                : _c("h3", { staticClass: "category-name" }, [
                    _vm._v(" " + _vm._s(_vm.$route.meta.title) + " ")
                  ]),
              _c("div")
            ],
            1
          ),
      _c("v-progress-linear", {
        attrs: {
          active: _vm.showOverlay,
          indeterminate: _vm.showOverlay,
          absolute: "",
          bottom: "",
          color: "primary"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }